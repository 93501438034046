<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" ref="viewPlanItemModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" v-if="selectedPlanItem">
          <div class="modal-body">
            <h5>{{this.selectedPlanItem.health_planner_card.title}}</h5>
            <p v-if="this.selectedPlanItem.health_planner_card.description">{{this.selectedPlanItem.health_planner_card.description}}</p>
          </div>
          <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-outline-secondary"
                    data-dismiss="modal"
            >
              Close
            </button>
            <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    @click.stop="markItemCompleted"
                    v-if="!this.selectedPlanItem.client_completed"
            >
              <i class="fad fa-check-circle mr-1"></i>
              Mark as Completed
            </button>
            <button
                    type="button"
                    class="btn btn-primary"
                    disabled="disabled"
                    v-else
            >
              <i class="fad fa-check-circle mr-1"></i>
              Completed
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row text-center my-5" v-if="busy">
        <div class="col">
          <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
        </div>
      </div>
      <div v-else>
        <div class="row mb-3">
          <div class="col-md-8 mx-auto">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <h5 class="mb-3">{{plan.name}}</h5>
                <full-calendar
                        class="full-sized-calendar"
                        ref="fullCalendar"
                        defaultView="timeGridDay"
                        height="auto"
                        :header="headerOptions"
                        :plugins="calendarPlugins"
                        :weekends="true"
                        :events="calendarEvents"
                        :editable="false"
                        :selectable="true"
                        :event-time-format="eventTimeFormat"
                        :first-day="1"
                        :min-time="'06:00:00'"
                        :slot-duration="'01:00:00'"
                        :all-day-slot="false"
                        :display-event-time="false"
                        :event-text-color="'#ffffff'"
                        :event-class-name="'font-weight-bold'"
                        @eventClick="handleEventClick"
                        @eventRender="eventRender"
                ></full-calendar>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
require("@fullcalendar/core/locales/en-gb.js");
require("@fullcalendar/core/main.css");
require("@fullcalendar/daygrid/main.css");
require("@fullcalendar/timegrid/main.css");
export default {
  props: [],
  data() {
    return {
      busy: true,
      plan: null,
      calendarPlugins: [timeGridPlugin, interactionPlugin],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        meridiem: false,
        hour12: false
      },
      headerOptions: {
        left: "prev,next",
        center: "title",
        right: "today"
      },
      selectedPlanItem: null,
    };
  },
  methods: {
    fetchHealthPlan() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/client/health-planner/" + this.$route.params.id)
              .then(({ data }) => {
                this.plan = data;
                this.busy = false;
              });
    },
    handleEventClick(data) {
      this.selectedPlanItem = this.plan.items.find(item => {
        return item.id == data.event.id;
      })

      let element = this.$refs.viewPlanItemModal;
      $(element).modal('show')
    },
    markItemCompleted() {
      this.$axios.post(process.env.VUE_APP_API_URL + '/client/health-planner/mark-item-completed', {
        client_health_plan_item_id: this.selectedPlanItem.id
      }).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.fetchHealthPlan();
        let element = this.$refs.viewPlanItemModal;
        $(element).modal('hide');
      });
    },
    eventRender(event, element) {
      console.log(event);
      console.log(element);

      let item = this.plan.items.find(item => {
        return item.id == event.event.id;
      })

      if (item.client_completed){
        event.el.innerHTML = event.el.innerHTML.replace(
                event.el.text,
                "<i class='fad fa-check-circle mx-2'></i><del>" + event.el.text + "</del>"
        );
      }
    }
  },
  mounted() {
    this.fetchHealthPlan();
  },
  filters: {
  },
  computed: {
    user() {
      return this.$store.user;
    },
    calendarEvents: function () {
      return this.plan.items.map(obj=> (
              { ...obj,
                title: obj.health_planner_card.title,
                date: moment.utc(obj.date).local().format('YYYY-MM-DD HH:mm:ss'),
                color: obj.client_completed ? '#28a745' : '#9293BC',

              })
      );
    }
  },
  components: {
    FullCalendar
  }
};
</script>

<style>
  .fc-title{
    font-size: larger !important;
  }
</style>
